// utils/install.js

import config from "../config";
import sendRequest from "./sendRequest";

const installExtensionAction = async (source = "") => {
  // Step 1: Get the latest version from the API
  try {
    // Send an asynchronous GET request to get the latest version
    const latestVersionData = await sendRequest('website_get_last_version', 'GET');

    // Extract the latest version from the response
    const latestVersion = latestVersionData.version + "b";

    // Step 2: Prepare parameters for the user_downloaded_file API request
    const params = {
      action: 'user_downloaded_file',
      version: latestVersion,
      source: source || "unknown",
    };

    // Step 3: Send an asynchronous GET request to the user_downloaded_file API
    const data = await sendRequest('user_downloaded_file', 'GET', params);

    // Handle the API response as needed
    // console.log('API Response:', data);

    // Step 4: Trigger the download link
    const extensionUrl = config.extensionUrl;
    const downloadLink = document.createElement('a');
    downloadLink.href = extensionUrl;
    downloadLink.target = '_blank'; // Open in a new tab
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

  } catch (error) {
    // Handle errors
    console.error('Error in installExtensionAction:', error);
  }
};

export { installExtensionAction };
