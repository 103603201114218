import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import PageComponent from "../components/PageComponent";

import "../assets/css/versions.css";
import { fetchVersionsData } from "../utils/versions";

const VersionsPage = () => {
  const [versionsData, setVersionsData] = useState([]);

  useEffect(() => {
    fetchVersionsData().then((response) => {
      setVersionsData(response.data);
    });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const renderVersionCard = (versionData) => (
    <div className="version-card">
      <span className="date">{versionData.date}</span>
      <span className="version">{versionData.version}</span>
      <h3>{versionData.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: versionData.description }} />
      {/* <Link to="/" className="btn">
        Return to Home
      </Link> */}
    </div>
  );

  return (
    <PageComponent>
      <div className="versions-page">
        <div className="container">
          <h2>Versions</h2>
          <div className="versions">{versionsData.map(renderVersionCard)}</div>
        </div>
      </div>
    </PageComponent>
  );
};

export default VersionsPage;
