// Logo.js

import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../assets/img/favicon.png";

import "../assets/css/logo.css";

const Logo = () => {
  return (
    <div className="logo-block">
      <Link to="/" className="logo-link">
        <img src={logoImage} width="100" className="logo" alt="Logo" />
      </Link>
      <span className='logo-text'>Geo Parser</span>
    </div>
  );
};

export default Logo;
