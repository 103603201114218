// utils/sendRequest.js
const sendRequest = async (action, method, params = {}) => {
  const apiUrl = "https://plugin.geo-fast.com.ua/api/v1/"; // Replace with your actual API endpoint
  console.log(apiUrl)
  console.log(action)
  
  // Check if it's a GET or HEAD request
  let url = `${apiUrl}?action=${action}`;
  console.log(url)

  if (method.toUpperCase() === 'GET' || method.toUpperCase() === 'HEAD') {
    // Append query parameters to the URL
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    if(queryString){
      url += `&${queryString}`;
    }
  }

  const requestOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers if needed
    },
  };

  // Include the body for non-GET and non-HEAD requests
  if (method.toUpperCase() !== 'GET' && method.toUpperCase() !== 'HEAD' && params) {
    requestOptions.body = JSON.stringify(params);
  }

  try {
    const response = await fetch(url, requestOptions);
    console.log(response)

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in sendRequest for action ${action}:`, error);
    throw error;
  }
};

export default sendRequest;
