import React, { useState } from "react";
// import { Link } from "react-router-dom";
import "../assets/css/contact.css";
import PageComponent from "../components/PageComponent";
import { sendContactFormData } from "../utils/contact";

const ContactPage = () => {
  const initialFormData = {
    name: "",
    email: "",
    orderNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [notification, setNotification] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call a function to send form data to the server
      await sendContactFormData(formData);

      // Reset the form and show a green notification
      setFormData(initialFormData);
      setNotification({
        type: "success",
        message: "Форма успішно відправлена!",
      });
    } catch (error) {
      // Handle the error and show a red notification
      setNotification({
        type: "error",
        message: "Відправка форми не вдалася. Будь ласка, спробуйте ще раз.",
      });
    }
  };

  return (
    <PageComponent>
      <div className="contact-page">
        <div className="container">
          <h2 className="text-center">Напишіть нам</h2>

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Імʼя*</label>
              <input
                type="text"
                id="name"
                placeholder="Geo Parser"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">E-mail*</label>
              <input
                type="email"
                id="email"
                placeholder="geo@parser.pro"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="orderNumber">Номер замовлення</label>
              <input
                type="text"
                id="orderNumber"
                placeholder="Номер Вашого замовлення(якщо є)"
                name="orderNumber"
                value={formData.orderNumber}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Повідомлення*</label>
              <textarea
                id="message"
                rows="5"
                placeholder="Ваші пропозиції, скарги та слова подяки писати тут..."
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="btn">
              Надіслати
            </button>

            {/* Notification */}
            {notification && (
              <div
                style={{
                  color: notification.type === "success" ? "green" : "red",
                }}
              >
                {notification.message}
              </div>
            )}
          </form>

          {/* <Link to="/" className="btn">Return to Home</Link> */}
        </div>
      </div>
    </PageComponent>
  );
};

export default ContactPage;
