import React from "react";
import PageComponent from "../components/PageComponent";

import HeroBanner from "../components/pages/home/HeroBanner";
import VideoPlayer from "../components/VideoPlayer";

import "../assets/css/home.css";

const HomePage = () => {
  const heroContent = () => (
    <div className="hero-content">
      <h1>Geo Parser Pro</h1>
      <h2>Ваш помічник у кабінеті ДЗК</h2>
      <p>
        Забудьте про зайві кліки – встановіть розширення та отримуйте потрібні
        дані з електронного кабінету ДЗК одним натисканням*!
      </p>
      <a href="#" className="btn">
        Встановити розширення
      </a>
    </div>
  );

  const featuresList = () => (
    <ul className="features-list">
      <li>
        <span>🌐</span> <h4>Автоматизація Завантаження</h4>{" "}
        <p>
          Це розширення робить процес завантаження даних з Державного Земельного
          Кадастру швидшим та зручнішим
        </p>
      </li>
      <li>
        <span>🔍</span> <h4>Пошук за Кадастровим Номером</h4>{" "}
        <p>
          Просто додайте список кадастрових номерів та отримайте потрібні файли
          без зайвих зусиль
        </p>
      </li>
      <li>
        <span>📂</span> <h4>Організація Файлів</h4>{" "}
        <p>
          Розширення надає можливість організовувати завантажені файли у
          відповідності до Ваших назв проєктів
        </p>
      </li>
    </ul>
  );

  return (
    <PageComponent>
      <div className="home">
        <HeroBanner />

        <section className="features">
          <div className="container">
            <h3 className="text-center">Основні функції</h3>
            {featuresList()}
          </div>
        </section>

        <section className="workVideo">
          <div className="container">
            <h3 className="text-center">Відео роботи плагіну</h3>
            <VideoPlayer />
          </div>
        </section>
      </div>
    </PageComponent>
  );
};

export default HomePage;
