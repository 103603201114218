import React from 'react';

import { installExtensionAction } from '../../../utils/install';

import "../../../assets/css/hero.css";

const HeroBanner = () => {
  return (
    <section className="hero-banner">
      <div className="hero-content">
        <h1>Ваш помічник у кабінеті ДЗК</h1>
        <p>Забудьте про зайві кліки – встановіть розширення та отримуйте потрібні дані з електронного кабінету ДЗК одним натисканням*!</p>
        <button className="install-button" onClick={async () => { await installExtensionAction("main-page-hero-banner") }}>Встановити розширення</button>
      </div>
    </section>
  );
};

export default HeroBanner;
