import React, { useEffect, useRef } from 'react';

const workProcessVideo = require('../assets/work_process.mp4');

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('canplaythrough', () => {
        videoElement.play();
      });
    }
  }, []);

  const videoStyles = {
    width: "100%"
  }

  return (
    <div className="video-box">
      <video src={workProcessVideo} style={videoStyles} controls loop controlsList="nodownload">
        <source src={workProcessVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
