import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
  const location = useLocation();
  const menuItems = [
    { path: '/', label: 'Головна' },
    { path: '/versions', label: 'Версії' },
    { path: '/contact', label: 'Підтримка' },
    { path: 'https://plugin.geo-fast.com.ua/files/v/2.1.2/Інструкція з користування.pdf', target: '_blank',label: "Інструкція"},
    { path: 'https://plugin.geo-fast.com.ua/files/v/2.1.2/Активація ліцезії.pdf', target: '_blank', label: "Як активувати ліцензію"},
  ];

  return (
    <ul className='menu-list'>
      {menuItems.map((item, index) => (
        <li key={index}>
          <Link to={item.path} target={item.target} className={location.pathname === item.path ? 'active' : ''}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
