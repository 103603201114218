// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Assuming your main App component is in App.js
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to measure performance, you can uncomment the following line.
// Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
