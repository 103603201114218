// utils/versions.js

import sendRequest from "./sendRequest";

const fetchVersionsData = async () => {
  const actionParam = "website_get_versions_data";

  try {
    // Send an asynchronous GET request using sendRequest
    const data = await sendRequest(actionParam, 'GET');

    // Handle the API response as needed
    // console.log('API Response:', data);

    return data;
  } catch (error) {
    // Handle errors
    console.error("Error in fetchVersionsData:", error);
    throw error;
  }
};

export { fetchVersionsData };
