import React from "react";
import "../assets/css/footer.css";
import Logo from "./Logo";

const Footer = () => {
  const supportEmail = "support@plugin.geo-fast.com.ua";

  return (
    <footer>
      <div className="container">
        <div className="footer-content">
          <div className="footer-left">
            <Logo />
          </div>
          <div className="footer-right">
            <p>
              Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </p>
            <p>
              <a href="#">Політика конфіденційності</a>
            </p>
          </div>
        </div>
        <p className="copywriting">&copy; 2024 Всі права захищено.</p>
      </div>
    </footer>
  );
};

export default Footer;
