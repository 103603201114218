// NotFoundPage.js
import React from "react";
import globalStyles from "./../assets/css/global.module.css";

import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const goHomeLinkStyle = {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#007bff", // Primary button color
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    marginTop: "20px",
  };

  return (
    <div className={globalStyles.notFoundContainer}>
      <h1 style={{ marginTop: 0 }}>404</h1>
      <p>На жаль, сторінка, яку ви шукаєте, не існує :(</p>

      <Link to="/" style={goHomeLinkStyle}>
        На головну
      </Link>
    </div>
  );
};

export default NotFoundPage;
