import { React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageComponent = ({ children }) => {
  const { pathname } = useLocation(); // Get the current path
  
  const ProjectName = "Geo Parser Pro"

  useEffect(() => {
    // Update the document title based on the current path
    switch (pathname) {
      case '/':
        document.title = ProjectName + ' | ' +  'Головна';
        break;
      case '/versions':
        document.title = ProjectName + ' | ' + 'Версії';
        break;
      case '/contact':
        document.title = ProjectName + ' | ' + 'Контакти';
        break;
      default:
        document.title = ProjectName;
    }
  }, [pathname]); // Re-render the effect whenever the pathname changes

  return (
    <div className="page">
      {children}
    </div>
  );
};

export default PageComponent;
