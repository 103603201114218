// utils/contact.js

import sendRequest from "./sendRequest";

const sendContactFormData = async (formData) => {
  try {
    const actionParam = "website_send_contact_form";

    formData.action = actionParam;
    const response = await sendRequest('website_send_contact_form', 'POST', formData);

    // Handle the response as needed
    console.log('API Response:', response);

    return response;
  } catch (error) {
    // Handle errors
    console.error("Error in sendFormData:", error.message);
    throw error;
  }
};

export { sendContactFormData };
